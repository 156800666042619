<template>
  <v-container fluid class="primary darken-4 white--text text-center">
    <p class="mb-0">2020 © JJRG Express Delivery</p>
    <p class="text-h6">Powered by Cyber Help Solutions</p>
  </v-container>
</template>

<script>
export default {
  name: "JFooter"
};
</script>
