<template>
  <v-app id="app">
    <j-navbar />
    <v-main>
      <j-header />
      <j-about-us />
      <j-our-benefits />
      <j-requirements />
      <j-responsabilities />
      <j-start-working />
      <j-work-with-us />
      <j-contact-us />
      <j-footer />
    </v-main>
  </v-app>
</template>

<script>
import JNavbar from "./components/JNavbar.vue";
import JHeader from "./components/JHeader.vue";
import JAboutUs from "./components/JAboutUs.vue";
import JOurBenefits from "./components/JOurBenefits.vue";
import JRequirements from "./components/JRequirements.vue";
import JResponsabilities from "./components/JResponsabilities.vue";
import JStartWorking from "./components/JStartWorking.vue";
import JWorkWithUs from "./components/JWorkWithUs.vue";
import JContactUs from "./components/JContactUs.vue";
import JFooter from "./components/JFooter.vue";

export default {
  name: "App",

  components: {
    JNavbar,
    JHeader,
    JAboutUs,
    JOurBenefits,
    JRequirements,
    JResponsabilities,
    JStartWorking,
    JWorkWithUs,
    JContactUs,
    JFooter
  }
};
</script>

<style lang="scss">
#app {
  overflow-x: hidden;
}
</style>
