<template>
  <v-container id="start-working">
    <v-row align="center" justify-xl="space-around">
      <v-col cols="12" sm="6" xl="4">
        <h2 class="text-h5 primary--text text-uppercase font-weight-bold">
          Ready to start working?
        </h2>
        <p>
          We are looking for folks who enjoy healthy competition, care about
          results, and expect to succeed.
        </p>
      </v-col>
      <v-col class="delivery" cols="12" sm="6" xl="4">
        <v-img
          src="../assets/images/bus-08.svg"
          class="carro"
          width="300"
          contain
        />
        <v-img
          src="../assets/images/casa-14.svg"
          class="casa"
          width="70%"
          max-height="100%"
          contain
        />
        <v-img
          src="../assets/images/persona carrito-17.svg"
          class="person"
          height="60%"
          width="50%"
          contain
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "JStartWorking"
};
</script>

<style lang="scss" scoped>
#start-working {
  .delivery {
    min-height: 400px;
    position: relative;

    .carro {
      position: absolute;
      bottom: 60px;
    }

    .casa {
      position: absolute;
      bottom: 0;
      right: -20px;
    }

    .person {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
