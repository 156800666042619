<template>
  <v-container>
    <v-row justify="center" justify-lg="space-around" align="center">
      <v-col cols="8" sm="6" xl="4">
        <v-img src="../assets/images/icono1-11.svg" />
      </v-col>
      <v-col cols="12" sm="6" xl="4">
        <h2 class="ml-16 primary--text text-h5 text-uppercase font-weight-bold">
          Our Benefits
        </h2>
        <v-list dense color="transparent">
          <v-list-item v-for="(benefit, i) in benefits" :key="i" two-line>
            <v-list-item-icon>
              <v-icon color="primary" large>mdi-check</v-icon>
              <v-list-item-content class="ml-4">
                {{ benefit }}
              </v-list-item-content>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "JOurBenefits",

  data() {
    return {
      benefits: [
        "Opportunities for advancement",
        "Paid Training",
        "Health Insurance and Benefits (after 90 days)",
        "Paid Time Off",
        "Potential for bonuses and or incentives"
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.benefits-container {
  width: 500px;
}
</style>
