import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_EMAIL_API_URL
});

const sendForm = async ({ name, email, phone }) => {
  try {
    const result = await api.post("/", {
      company_name: process.env.VUE_APP_COMPANY,
      email: email,
      name: name,
      phone_number: phone,
      secret_key: process.env.VUE_APP_EMAIL_API_KEY,
      to: process.env.VUE_APP_FORM_EMAIL
    });
    console.log(result);
    if (result.data === "Email sent") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default api;

export { sendForm };
