<template>
  <v-container id="apply" class="primary pa-0 contact" fluid>
    <v-container
      class="white contact__container elevation-5 px-8 px-sm-16 pt-8"
    >
      <h2 class="primary--text text-h5 font-weight-bold text-center">
        Work wiht us!
      </h2>
      <v-form ref="applyForm" class="px-md-16" v-model="form.validate">
        <v-text-field
          :rules="rules.name"
          filled
          rounded
          hide-details
          label="Full Name"
          type="text"
          class="mt-4"
          dense
          color="primary"
          v-model="form.name"
        />
        <v-text-field
          :rules="rules.email"
          filled
          rounded
          hide-details
          label="Email"
          type="email"
          class="mt-4"
          dense
          color="primary"
          v-model="form.email"
        />
        <v-text-field
          :rules="rules.phone"
          filled
          rounded
          hide-details
          label="Phone"
          type="phone"
          class="mt-4"
          dense
          color="primary"
          v-model="form.phone"
        />
        <v-btn
          @click="sendForm"
          :disabled="!form.validate || applied"
          :loading="applying"
          block
          class="mt-4 primary--text"
          rounded
          color="accent"
        >
          Apply Now!
        </v-btn>
        <v-alert
          v-if="alert.value"
          :type="alert.type"
          :dismissible="alert.type === 'error' ? true : false"
          class="mt-5"
        >
          {{ alert.message }}
        </v-alert>
      </v-form>
      <v-img
        src="../assets/images/cajas-18.svg"
        class="mt-4 px-md-16"
        contain
        max-height="150"
      />
    </v-container>
  </v-container>
</template>

<script>
import { sendForm } from "../api/ContactForm";

export default {
  name: "JWorkWithUs",

  data() {
    return {
      alert: {
        message: "",
        type: "success",
        value: false
      },

      applied: false,

      applying: false,

      form: {
        name: "",
        email: "",
        phone: ""
      },

      rules: {
        email: [
          v => !!v || "E-mail is required",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        name: [v => !!v || "Name is required"],
        phone: [
          v => !!v || "Phone is required",
          v =>
            /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
            "Phone must be valid"
        ]
      }
    };
  },

  methods: {
    async sendForm() {
      if (this.$refs.applyForm.validate() && this.applied === false) {
        this.applying = true;
        try {
          if (await sendForm(this.form)) {
            this.alert.value = true;
            this.alert.type = "success";
            this.alert.message =
              "Thanks for trust in us, we will contact you soon.";
            this.applied = true;
          } else {
            this.alert.value = true;
            this.alert.type = "error";
            this.alert.message = "Something went wrong, retry later";
            this.applied = false;
          }
        } finally {
          this.applying = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.contact {
  @media (min-width: 600px) {
    position: relative;
    width: 100%;
    height: 450px;

    &__container {
      position: absolute;
      max-width: 70%;
      min-height: 100%;
      top: 40px;
      right: 15%;
    }
  }

  @media (min-width: 960px) {
    &__container {
      max-width: 65%;
    }
  }

  @media (min-width: 1264px) {
    height: 500px;
  }

  @media (min-width: 1904px) {
    &__container {
      max-width: 55%;
      right: 22.5%;
    }
  }
}
</style>
