<template>
  <v-app-bar absolute color="transparent" flat prominent>
    <v-row
      justify="center"
      justify-sm="space-between"
      align="center"
      class="px-lg-10"
    >
      <v-col cols="10" sm="3" md="2" class="px-xl-10">
        <v-img src="../assets/logo.svg" contain alt="Logo" />
      </v-col>
      <v-col class="d-none d-sm-flex justify-end align-center" sm="4" md="3">
        <v-icon color="white" class="mr-4" x-large>mdi-map-marker</v-icon>
        <p class="accent--text text-subtitle-2 text-lg-h6 my-0">
          901 Bay Marina Drive,
          <br />
          National City, CA
        </p>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: "JNavbar"
};
</script>
