<template>
  <v-container>
    <v-row justify="center" justify-lg="space-around" align="center">
      <v-col cols="8" sm="6" xl="4">
        <v-img src="../assets/images/icono3-13.svg" />
      </v-col>
      <v-col cols="12" sm="6" xl="4">
        <h2 class="text-h5 primary--text text-uppercase font-weight-bold ml-16">
          Duties and Responsabilitiess
        </h2>
        <v-list dense color="transparent">
          <v-list-item
            v-for="(responsability, i) in responsabilities"
            :key="i"
            class="mt-12 mt-md-4"
          >
            <v-list-item-icon>
              <v-icon color="primary" large>mdi-check</v-icon>
              <v-list-item-content class="ml-4">
                {{ responsability }}
              </v-list-item-content>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "JResponsabilities",

  data() {
    return {
      responsabilities: [
        "Drive our delivery vehicle safely, at all times.",
        "Learn to use a smart phone/device for routing information and safety metrics.",
        "Problem solve when necessary.",
        "Do what it takes to put smiles on our customer's faces.",
        "Lift packages up to 50lbs. (Most will be considerably less and never more than 50lbs.)",
        "To get in and out of our van multiple times a shift."
      ]
    };
  }
};
</script>

<style></style>
