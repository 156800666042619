<template>
  <v-container class="pt-16">
    <v-row>
      <v-col class="d-flex flex-column justify-center aling-center text-center">
        <h2>Contact Us!</h2>
        <p>
          JJRG EXPRESS DELIVERY
          <br />
          <v-icon color="primary" large>mdi-map-marker</v-icon>
          901 Bay Marina Drive, National City, CA
          <br />
          <a href="mailto:jobs@jjrgaexpress.com">
            jobs@jjrgaexpress.com
          </a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
