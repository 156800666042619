<template>
  <v-container>
    <v-row justify="center" justify-lg="space-around" align="center">
      <v-col cols="12" sm="6" xl="4" order="2">
        <h2 class="primary--text text-h5 text-uppercase font-weight-bold ml-16">
          Requirements
        </h2>
        <v-list dense color="transparent">
          <v-list-item
            v-for="(requirement, i) in requirements"
            :key="i"
            two-line
          >
            <v-list-item-icon>
              <v-icon color="primary" large>mdi-check</v-icon>
              <v-list-item-content class="ml-4">
                {{ requirement }}
              </v-list-item-content>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="8" sm="6" xl="4" order="1" order-sm="3">
        <v-img src="../assets/images/icono2-12.svg" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "JRequirements",

  data() {
    return {
      requirements: [
        "Eligible to work in the U.S",
        "At least 21 years of age",
        "Have a decent driving record (not more than one ticket)",
        "Pass a pre-employment drug test- This includes Marijuana"
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.requirements-container {
  width: 500px;
}
</style>
