<template>
  <v-container class="py-16 my-16" fluid>
    <v-row class="px-sm-12" align="center" justify="space-around">
      <v-col sm="6" md="4" lg="3">
        <h2 class="text-h5 font-weight-bold text-uppercase primary--text">
          About Us
        </h2>
        <p class="text-justify">
          We are a new Amazon Delivery Service Partner looking to hire our
          starting team. Once you are hired, you will be delivering Amazon
          packages safely in one of our cool Amazon branded vans.
        </p>
      </v-col>
      <v-col class="d-flex" md="6">
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <v-img src="../assets/images/bus-08.svg" width="100%" contain />
          </v-col>
          <v-col cols="4" class="d-none d-md-flex align-end boxes">
            <v-img src="../assets/images/caja-09.svg" height="80%" contain />
            <v-img src="../assets/images/caja-10.svg" height="100%" contain />
            <div class="pa-2 pa-lg-3 mr-n12 secondary belt" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "JAboutUs"
};
</script>

<style lang="scss" scoped>
.boxes {
  position: relative;
  height: 60%;

  .belt {
    position: absolute;
    width: 180%;
    bottom: 0px;
    left: -10px;
  }
}
</style>
