<template>
  <v-container
    id="header"
    class="primary d-flex flex-column justify-space-around align-center px-0"
    fluid
  >
    <div class="d-flex flex-column align-center justify-center mt-16">
      <h1
        class="white--text font-weight-bold text-h3 text-uppercase text-center mb-4"
      >
        Express Delivery
      </h1>
      <v-btn
        rounded
        color="accent"
        class="mb-sm-16 primary--text"
        large
        width="250"
        href="#apply"
      >
        Apply now!
      </v-btn>
    </div>
    <v-container class="pa-0 mt-sm-16" fluid>
      <v-row no-gutter>
        <v-col class="boxes d-inline-flex align-end py-0 px-md-16" cols="12">
          <v-img
            src="../assets/images/caja-03.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-04.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-05.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-06.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-07.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-03.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-04.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-05.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-06.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-07.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-03.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-04.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-05.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-06.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
          <v-img
            src="../assets/images/caja-07.svg"
            class="mx-4"
            alt="box"
            min-width="200"
            max-height="300"
            contain
          />
        </v-col>
        <v-col class="base pa-4"></v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "JHeader"
};
</script>

<style lang="scss" scoped>
#header {
  min-height: 100vh;
  padding-top: 130px;
}

.boxes {
  animation: conveyer-belt 5s linear infinite;
  position: relative;
  height: 260px;
  width: 200%;

  img {
    height: 55%;

    @media (min-width: 1264px) {
      height: 70%;
    }

    @media (min-width: 1904px) {
      height: 100%;
    }
  }
}

.base {
  background-color: #ffffff;
  position: relative;
  bottom: 0.8rem;

  @media (min-width: 1904px) {
    bottom: 1.2rem;
  }
}

@keyframes conveyer-belt {
  from {
    right: 0;
  }

  to {
    right: 1225px;
  }
}
</style>
